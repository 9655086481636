.container {
  position: absolute;
  top: 64px;
  right: 64px;

  .scrollable {
    margin-top: 16px;
    max-height: 64vh;
    padding: 8px;
    overflow: scroll;

    > .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 104px;
      padding-right: 16px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 8px;
      }
      > .line {
        width: 4px;
        height: 64px;
        border-radius: 0 4px 4px 0;

        &.info {
          background-color: #00d811;
        }
        &.warn {
          background-color: #fe4a42;
        }
        &.alarm {
          background-color: #1b65dd;
        }
      }
      > .type {
        width: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
        font-size: 14px;

        > .text {
          &.info {
            color: #00d811;
          }
          &.warn {
            color: #fe4a42;
          }
          &.alarm {
            color: #1b65dd;
          }
        }
      }
      > .details {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .message {
          margin-top: 4px;
          font-size: 16px;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        > .subDetails {
          margin-top: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > .serialNo {
            font-size: 14px;
          }
          > .createdAt {
            margin-top: 4px;
            font-size: 12px;
            color: gray;
          }
        }
      }
    }
  }
  .footer {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
}
