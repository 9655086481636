@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form {
    display: flex;

    > .item {
      margin: 0;

      &:not(:first-child) {
        margin-left: 16px;
      }
      .label {
        font-size: 16px;
        color: #444;
        font-weight: normal;
      }
      .field {
        &.customer {
          width: 160px;
        }
        &.serialNo {
          width: 160px;
        }
      }
      .calendar {
        &:hover {
          background-color: white;
        }
      }
    }
    > .search {
      margin-left: 8px;
    }
    > .reset {
      margin-left: 8px;
    }
  }
  .stats {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 24px;
    border-radius: 4px;
    cursor: pointer;

    > .column {
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;

      &.active {
        background-color: rgba(#1b65dd, 0.2);
        border-radius: 8px;
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
      &:after {
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        content: '';
        width: 1px;
        height: 80%;
        background-color: #ddd;
      }
    }
  }
  .checkbox {
    cursor: pointer;
  }
}
