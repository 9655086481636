@import '../../../styles/common.scss';

.container {
  width: 100%;
  height: 100%;

  .map {
    width: 100%;
    height: 100%;
  }
}
