@import '../../../../../styles/common.scss';

.wrapper {
  max-height: 80vh;
  overflow-y: scroll;
}

.subTitle {
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
  color: #444;
  font-weight: 500;
}

.flexBox {
  display: flex;
  flex-wrap: wrap;
}

.box {
  width: 30%;
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.textareaBox {
  min-height: 150px !important;
}
