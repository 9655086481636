@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.search {
  font-size: 14px;
  color: #444;
  font-weight: normal;
}

.modalButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  font-size: 16px;
  border: 0;
  background-color: #1677ff;
  cursor: pointer;
  border-radius: 6px;
  color: #fff;
}
