* {
  margin: 0;
  padding: 0;
  word-break: keep-all;
  font-family: 'Noto Sans KR', sans-serif;
}

html {
  font-size: 16px;
  min-width: 1280px;
}
body {
  width: 100%;
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  text-decoration: none;
  color: inherit;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ant-table-cell {
  cursor: pointer;
}
textarea {
  resize: none !important;
}
