@import '../../styles/common.scss';

.wrapper {
  background-image: url('../../assets/login/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formInputBox {
  width: 100%;
}

.logo {
  width: 100%;
  max-width: 120px;
}

.loginBox {
  min-width: 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  justify-content: center;
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.4);
}
