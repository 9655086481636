@import '../../../styles/common.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main {
  padding: 16px;
}
