@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form {
    display: flex;

    > .item {
      margin: 0;

      &:not(:first-child) {
        margin-left: 16px;
      }
      .label {
        font-size: 16px;
        color: #444;
        font-weight: normal;
      }
      .field {
        &.customer {
          width: 160px;
        }
        &.serialNo {
          width: 160px;
        }
      }
      .calendar {
        &:hover {
          background-color: white;
        }
      }
    }
    > .search {
      margin-left: 8px;
    }
    > .reset {
      margin-left: 8px;
    }
  }
  .status {
    margin: 0 auto;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $positive-color;

    &.negative {
      background-color: $negative-color;
    }
  }
  .heading {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-top: 20px;

    > span {
      font-size: 0.9em;
      color: #1b65dd;
      font-weight: 600;
    }
  }
  .table {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    overflow-x: scroll;
    background-color: white;

    > .column {
      display: flex;
      align-items: center;

      &:last-of-type {
        border-bottom: 0;
      }
      > .title {
        font-size: 16px;
        color: #333;
        width: 100px;
        flex-shrink: 0;
      }
      > .list {
        display: flex;
        align-items: center;

        &.top {
          border-top: 1px solid #ddd;

          > li {
            background-color: #f0f0f0;
          }
        }
        > li {
          width: 150px;
          text-align: center;
          padding: 6px;
          background-color: white;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;

          &:first-of-type {
            border-left: 1px solid #ddd;
          }
        }
      }
    }
  }
}
