@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .calender {
    &:hover {
      background-color: white;
    }
  }
}
