@import '../../../styles/common.scss';

.container {
  padding: 16px;
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 124px);
  background-color: #e9eff8;

  .card {
    padding: 0;
    cursor: pointer;
    border: 1px solid #ddd;
    transition: all 0.2s ease-in;
    &:hover {
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.125);
    }
    &.select {
      border-color: #1b65dd;
    }
  }
}
