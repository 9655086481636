@import '../../../../styles/common.scss';

.signature {
  width: 100%;
  object-fit: contain;
}

.files {
  .title {
    font-size: 14px;
    color: #000000e0;
    display: block;
    margin-top: 24px;
    padding-bottom: 8px;
  }

  ul {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    > li a {
      background-color: #666;
      padding: 6px 12px;
      border-radius: 6px;
      color: white;
    }
  }
}
