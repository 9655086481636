@import '../../../styles/common.scss';

.wrapper {
  background-color: #e9eff8;
  padding: 32px;
  border-radius: 16px;
  min-height: calc(100vh - 156px);
  display: flex;
  flex-direction: column;
}

.headingTitle {
  font-size: 24px;
  font-weight: 500;
  color: #1b65dd;
  margin-bottom: 24px;
}
