@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .count {
    font-size: 14px;
    color: #444;
    font-weight: 500;
  }
}

.search {
  font-size: 14px;
  color: #444;
  font-weight: normal;
}
