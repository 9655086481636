@import '../../../../styles/common.scss';

.header {
  display: flex;
  justify-content: space-between;
  max-width: 840px;
  margin: auto;

  > .title {
    display: flex;
    align-items: center;
    gap: 8px;

    > img {
      width: 100px;
    }
    > p {
      font-size: 20px;
      color: #333;
    }
  }
  > .process {
    display: flex;
    gap: 2px;

    > .title {
      font-size: 18px;
      color: #333;
      margin-right: 10px;
    }
    > .column {
      border: 1px solid #ddd;

      > p {
        padding: 4px;
        background-color: #f0f0f0;
        text-align: center;
        font-size: 16px;
        border-bottom: 1px solid #ddd;
      }
      > div {
        padding: 4px;
        min-width: 80px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          width: 100px;
        }
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 840px;
  margin: auto;

  > .wrapper {
    margin-bottom: 4px;

    &.hide {
      @media print {
        display: none;
      }
    }
    &:last-of-type {
      margin-top: 60px;
    }
    > .title {
      display: block;
      font-size: 20px;
      background-color: #f0f0f0;
      text-align: center;
      font-weight: normal;
      padding: 4px 0;
      border: 1px solid #ddd;
      margin-bottom: 4px;
    }
    > .info {
      border: 1px solid #ddd;
      padding: 6px;
      width: 100%;
      min-height: 150px;
      box-sizing: border-box;

      &:focus {
        outline: 0;
      }
    }
    > .space {
      display: flex;
      justify-content: center;
      width: 100%;

      > .box {
        display: flex;
        border: 1px solid #ddd;
        margin-top: -1px;
        width: 100%;

        &.half {
          width: 50%;
        }
        &.triples {
          width: 33.33334%;
        }
        > .title {
          font-size: 18px;
          padding: 8px;
          font-weight: 500;
          color: #333;
          background-color: #f0f0f0;
          border-right: 1px solid #ddd;
          min-width: 100px;
        }
        > .desc {
          font-size: 16px;
          padding: 8px;
          color: #333;
          text-align: center;

          > .unit {
            &:before {
              content: ', ';
            }
            &:first-of-type {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
    > .table {
      box-sizing: border-box;
      border-collapse: collapse;
      width: 100%;

      > thead {
        > tr {
          background-color: #f0f0f0;
          border: 1px solid #ddd;

          > td {
            text-align: center;
            padding: 4px;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            border-right: 1px solid #ddd;
          }
        }
      }
      > tbody {
        > tr {
          border-bottom: 1px solid #ddd;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;

          > td {
            padding: 4px;
            font-size: 16px;
            color: #333;
            border-right: 1px solid #ddd;
            text-align: center;

            &:last-of-type {
              border-right: 0;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
