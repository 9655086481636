@import '../../../styles/common.scss';

.header {
  border-bottom: 1px solid #eee;
  padding: 0 16px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
  }
}

.menus {
  width: 800px;
  border-bottom: 0;
  > li {
    &::after {
      display: none;
    }
  }
}

.left {
  width: calc(100% - 150px);
  display: flex;
  align-items: center;
  gap: 12px;
}

.right {
  gap: 8px;
  width: fit-content;
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0 0 10px #cad5e2;
  border: 0;
}

.userDesc {
  margin-right: 12px;
  font-size: 12px;
  color: #444;
  font-weight: 500;
  white-space: nowrap;
}
