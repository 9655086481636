@import '../../../styles/common.scss';

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subTitle {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 24px;
}

.flexBox {
  width: 100%;
  display: flex;
  gap: 8px;
}

.fix {
  position: fixed;
  left: 48px;
  right: 48px;
  bottom: 48px;
}

.dataImg {
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.dataImgBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 640px;
}

.tableBox {
  max-width: 1280px;
}

.form {
  padding-bottom: 80px;
}

.row {
  margin-bottom: 16px;
}
