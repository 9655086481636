@import '../../../styles/common.scss';

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subTitle {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 24px;
  display: block;
}

.field {
  background-color: white;
  padding: 4px 11px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
}

.flexBox {
  width: 100%;
  display: flex;
  gap: 8px;
}

.fix {
  position: fixed;
  left: 48px;
  right: 48px;
  bottom: 48px;
}
