@import '../../../../styles/common.scss';

.marker {
  width: 60px;
  height: 60px;
  appearance: none;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
  }
}
